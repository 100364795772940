import {
  get
} from './http'
import {
  getDevice,
  getQueryVariable,
  getCookie,
  removeCookie,
  clearCookie,
  getCookieDomain,
  delCookie
} from './util'


import {
  getUserInfo
} from '@view/me/js/'
// import store from '../../store'
import {
  setBindingCustomerToSale
} from "@set/util";

export const getAutoWechatLogin = async (_this) => {
  /**
   * @method getAutoWechatLogin 微信端自动登录
   * @param {Object}  当前实例
   */


  if (process.env.NODE_ENV === 'development') {
    //生产环境禁止自动登录
    return false;
  }

  if (getDevice().isWechat) {
    //第一次进入页面时 如果是微信端 并且还没有登录 
    //其余时候则用户自主选择

    if (!_this.$store.state?.user?.token) {
      let user_info = getCookie("user_info"); //用户信息
      let token = getCookie("key"); //token
      if (user_info && token) {
        console.log(user_info, token);
        user_info = JSON.parse(decodeURIComponent(user_info));
        _this.$store.dispatch('doToken', token);
        // _this.$store.dispatch('doUserInfo', user_info);
        await getUserInfo();
        delCookie("user_info", '.19jiang.cn');
        delCookie("new_cookie", '.19jiang.cn');
        delCookie("key", '.19jiang.cn');
        removeCookie("key");
        removeCookie("new_cookie");
        const RENINE_SHOP_R_ID = localStorage['RENINE_SHOP_R_ID'];
        if (RENINE_SHOP_R_ID) {
          setBindingCustomerToSale(RENINE_SHOP_R_ID)
        }
        localStorage.removeItem('RENINE_SHOP_R_ID')
        sessionStorage.removeItem("RENINE_SHOP_I_ID");
        return false;
      } else {

        delCookie("new_cookie", '.19jiang.cn');
        delCookie("key", '.19jiang.cn');
        removeCookie("key");
        delCookie("new_cookie");

        // let ref = encodeURIComponent(w indow.location.href); //语义化链接
        //未登陆则将客服ID存到localStorage
        localStorage['RENINE_SHOP_R_ID'] = getQueryVariable('k');
        let inviter_id = sessionStorage['RENINE_SHOP_I_ID'] || getQueryVariable('inviter_id'); //邀请人ID  
        //微信登陆
        let ref = window.location.href;
        let wechatLoginAddress = await get('/v2.Wxauto/login', {
          params: {
            ref,
            inviter_id
          }
        });
        window.location.href = wechatLoginAddress
      }

    } else {
      //绑定客服
      setBindingCustomerToSale()
    }


  } else {
    //绑定客服
    setBindingCustomerToSale()
  }
}