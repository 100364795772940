import {
  Toast
} from 'vant';
import axios from '../../axios';
import store from '../../store'

axios.defaults.withCredentials = true
axios.create({
  timeout: 6000 //设置超时
})
export const http = async (url, params, args) => {

  /***
   * 方法说明
   * @method http 请求API数据接口
   * @param {url}<string> 请求网络地址
   * @param {args}<object> 请求参数
   * @returns {Axios object <string,unknown>}
   */

  try {
    return axios(url.indexOf('http') === -1 ? `${process.env.VUE_APP_API_HOST}${url}` : url, params).then(({
      data
    }) => {
      return new Promise((resolve, reject) => {
        if (args?.origin) {
          resolve(data)
          return
        }

        if (data.code == 10000) {
          Toast.clear();
          if (args?.result === true) {
            let key = args?.resultKey ? args?.resultKey : `result`;
            resolve(key == 'result' ? data.result || data : data)
          } else {
            resolve(data.result || data)
          }
        } else if (data.code == 11001) {

          if (args?.setupLogin === true || args?.setupLogin === undefined) {
            store.dispatch('doUserClear', "");
            store.dispatch('doSetupLogin', true)
          }

          if (args?.result === true) {
            let key = args?.resultKey ? args?.resultKey : `result`;
            resolve(key == 'result' ? data.result || data : data)
          } else {
            reject(data)
          }
        } else {

          if (args?.toast === true || args?.toast === undefined) {
            Toast(data.message);
          }
          if (args?.result === true) {
            let key = args?.resultKey ? args?.resultKey : `result`;
            resolve(key == 'result' ? data.result || data : data)
          } else {
            reject(data)
          }

        }
      })
    })
  } catch (error) {
    console.error(error.message)
  }
}

export const post = async (url, params, arg) => {

  return http(url, {
    headers: {
      'X-DS-KEY': store.state.user.token
    },
    method: 'POST',
    ...params
  }, arg)

}

export const form = async (url, params) => {
  return http(url, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-DS-KEY': store.state.user.token
    },
    method: 'POST',
    ...params
  })
}


export const get = async (url, params, arg) => {
  return http(url, {
    method: 'GET',
    ...params
  }, arg)
}



export function raw(reqUrl, type = 'POST', data = {}) {
  type = type.toUpperCase()
  let axiosData
  if (type === 'POST') {
    axiosData = {
      method: type,
      url: reqUrl,
      data: data
    }
  } else {
    if (JSON.stringify(data) !== '{}') {
      reqUrl += '?' + urlencode(data)
    }
    axiosData = {
      method: type,
      url: reqUrl
    }
  }
  return new Promise((resolve, reject) => {
    axios(axiosData).then(res => {
      resolve(res.data)

    }, error => {
      reject(error)
    })
  })
}