<template>
  <van-popup
    v-model="popupLoginShow"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <oLogin :boo.sync="popupLoginShow"></oLogin>
  </van-popup>
</template>

<script>
import { Popup, Dialog } from "vant";
import { mapState } from "vuex";
import oLogin from "@c/login/";
export default {
  data() {
    return {
      popupLoginShow: false,
    };
  },
  components: {
    vanPopup: Popup,
    oLogin,
  },
  computed: {
    ...mapState({
      setupLogin: (state) => state.setupLogin.setupLoginComponent,
    }),
  },
  created() {
    if (this.setupLogin) {
      this.popupLoginShow = true;
    }
  },
  watch: {
    setupLogin() {
      if (this.setupLogin) {
        this.fooSetupLogin();
      } else {
        this.popupLoginShow = false;
      }
    },
  },

  methods: {
    fooSetupLogin(next) {
      // if (this.setupLogin) {
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "该操作需要登陆",
      })
        .then(() => {
          // on confirm
          this.popupLoginShow = true;
        })
        .catch(() => {
          this.$store.dispatch("doSetupLogin", false);
        });
      // }
    },
  },

  // beforeRouteEnter(to, from, next) {
  //   // console.log(to.meta.token, !this.$store.getters.getToken);

  //   if (to.meta.token) {
  //     if (!this.$store.getters.getToken) {
  //       console.log("调用");
  //       this.fooSetupLogin(next);
  //       // store.dispatch('doSetupLogin', true)
  //     }
  //   } else {
  //     console.log("进入");
  //     next();
  //   }
  // },
};
</script>

<style lang="scss" scoped></style>
