import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store';
// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'
import './icons'
// import 'es6-promise/auto';
// import 'babel-polyfill';


var VueTouch = require('vue-touch')
Vue.use(VueTouch, {
  name: 'v-touch'
})

import {
  setDataLog
} from './util/set/util'
import('./assets/css/config.scss')
import {
  Lazyload
} from 'vant';
//懒加载
Vue.use(Lazyload, {
  lazyComponent: true,
});




// Vue.use(new VueSocketIO({
//   debug: store.state.common.node_env ? false : true,
//   connection: SocketIO(env.CHAT_PORT(), {
//     transports: ['websocket']
//   }),

// }));


Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp'];


Vue.directive('title', {
  // 修改标题
  inserted: function (el, binding) {
    setDataLog(binding.value)
  }
})


function add0(m) {
  return m < 10 ? '0' + m : m
}

Vue.filter('time', (time, mode = 1) => {
  var time = new Date(time * 1000);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  if (mode == 1) {
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  } else if (mode == 2) {
    return add0(m) + '-' + add0(d);
  } else {
    return add0(d);
  }

})




//微信打开小程序
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp'];

// 复制
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')