const state = {
  setReviewState: false,
  setReviewArrayPos: [-1, -1]
}

const mutations = {
  fChangeReviewState(state, value) {
    state.setReviewState = value;
  },

  fChangeReviewArrayPos(state, value) {
    state.setReviewArrayPos = value;
  }
}


export default {
  state,
  mutations
}