import {
  get
} from "@get/http";

const state = {
  kefu: [ //客服列表
    [],
    []
  ],
  OSS: {},
  inviter_id: 0,
  node_env: process.env.NODE_ENV === 'production',
  gradeLeven: [{
    inviterclass_id: 0,
    inviterclass_name: "粉丝"
  }, {
    inviterclass_id: 10,
    inviterclass_name: "VIP会员"
  }, {
    inviterclass_id: 11,
    inviterclass_name: "金绣班学员"
  }, {
    inviterclass_id: 12,
    inviterclass_name: "金绣弟子"
  }, {
    inviterclass_id: 13,
    inviterclass_name: "弟子"
  }, {
    inviterclass_id: 14,
    inviterclass_name: "分销商"
  }, {
    inviterclass_id: 15,
    inviterclass_name: "分院"
  }]
}

const mutations = {
  setChatStateKefu(state, arg) {
    state.kefu = arg;
  },
  setOss(state, arg) {
    state.OSS = arg;
  },
  setInviterId(state, arg) {
    state.inviter_id = arg;
  },
}

const actions = {
  async doSetInviterId(context, arg) {
    context.commit('setInviterId', arg)
  },
  async doSetOss(context, arg) {
    context.commit('setOss', arg)
  },
  async doSetChatStateKefu(context, arg) {
    let data = await get(`${process.env.VUE_APP_CHAT_PORT}/getServices`, {}, {
      result: true,
      resultKey: 'code',
      toast: false,
    });
    try {
      let userState = [
        [],
        []
      ];
      data.online.map((item) => {
        userState[0].push({
          name: item.nickname,
          kefu_state: 1,
          description: "在线",
          kefu_id: item.member_id,
          icon: "http://image.shop.19jiang.cn/home/mofang/alioss_603468fc4fb69.jpg",
        });
      });
      data.offline.map((item) => {
        userState[1].push({
          name: item.nickname,
          kefu_state: 0,
          description: "离线",
          kefu_id: item.member_id,
          icon: "http://image.shop.19jiang.cn/home/mofang/alioss_6034695f182dd.png",
        });
      });
      context.commit('setChatStateKefu', userState)
    } catch (error) {
      console.log(error);
    }





  },

}




export default {
  state,
  mutations,
  actions
}