<template>
  <div id="">
    <div class="loginInput  flex-container">
      <img class="icon" src="@image/login/login_yzm.png" />
      <input
        class="flex1"
        maxlength="4"
        type="text"
        name=""
        :value="value"
        @input="chageYZM($event.target.value)"
        placeholder="请输入验证码"
      />
      <div class="solid"></div>
      <img :src="pictureCodeUrl" class="yzm" @click="changePictureCode" />
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    value: {
      default: "",
    },
  },
  data: () => ({
    pictureCodeUrl: "",
  }),
  created() {
    //do something after creating vue instance
    this.changePictureCode();
  },
  methods: {
    chageYZM(val) {
      this.$emit("update:value", val);
    },
    changePictureCode() {
      this.pictureCodeUrl =
        process.env.VUE_APP_API_HOST + "/Seccode/makecode?r=" + Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.loginInput {
  // font-family:'pf';
  border-bottom: rem(2) solid #f5f5f5;
  .yzm {
    width: rem(180);
    height: rem(50);
  }
  // padding: rem(60) 0;
  padding: rem(60) 0 rem(28) 0;
  align-items: center;
  .icon {
    width: rem(30);
    height: rem(29);
    display: block;
  }
  .solid {
    width: rem(2);
    height: rem(24);
    background: #ebebeb;
    margin: 0 rem(24);
  }
  input {
    font-size: rem(27);
    color: #808080;
    border: none;
    width: rem(420);
    margin-left: rem(12);
  }
}
</style>
