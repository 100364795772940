import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import setupLogin from './modules/setupLogin'
import order from './modules/order'
import cart from './modules/cart'
import common from './modules/common'
import search from './modules/search'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    user,
    setupLogin,
    order,
    cart,
    common,
    search
  },
  plugins: [
    createPersistedState({
      key: '19JIANG_SHOP',
      paths: ['user', 'search']
    })
  ]

})