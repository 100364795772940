import {
  post
} from "@get/http";
import {
  getQueryVariable
} from "@get/util";

import store from '@/store'

export const setBindingCustomerToSale = (id) => {

  /**
   * 方法说明
   * @method setBindingCustomerToSale   客户绑定客服关系
   * @param {Number} id 客服ID
   * @return {vode} 
   */


  let k = getQueryVariable("k")
  let inviter_id = getQueryVariable("inviter_id");
  let sale_id = k ? k : inviter_id
  let seller_member_id = id ? id : sale_id;
  // store.dispatch('doSetInviterId', inviter_id)

  if (seller_member_id) {
    post(
      "/Memberaccount/bind_seller", {
        params: {
          seller_member_id,
        },
      }, {
        setupLogin: false,
        toast: false,
      }
    );
  }

}



export const dataAdd = async (to) => {
  if (to?.page) {
    await post('/lw.VisitorsLog/add', {
      data: {
        page: `${ to?.page } | ${to?.fullPath || (window.location.pathname + window.location.search)}`,
        goods_id: to?.goods_id
      }
    }, {
      toast: false
    })
  } else if (to?.name == 'HomeGoodsdetail') {
    return false;
  } else {
    await post('/lw.VisitorsLog/add', {
      data: {
        page: `${to.meta.title} | ${to.fullPath}`,
      }
    }, {
      toast: false
    })
  }
}


// 加载js
let scriptLoaded = {}
export function setLoadScript(code, url, callback) {
  if (typeof (scriptLoaded[code]) === 'undefined') {
    let script = document.createElement('script')
    script.src = url
    document.body.appendChild(script)

    script.onload = function () {
      scriptLoaded[code] = true
      callback()
    }
  } else {
    callback()
  }
}


export const setDataLog = (page, goods_id) => {
  /**
   * 方法说明
   * @method setDataLog   上传点击数据
   * @param {*string} page 希望上传的页面数据
   * @param {string} goods_id 希望上传的商品ID数据
   * @return {vode} 
   */
  post('/lw.VisitorsLog/add', {
    data: {
      page,
      goods_id
    }
  }, {
    toast: false
  })
}