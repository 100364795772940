import store from '../store'
import {
  getCookie
} from '@get/util'
const setAddtoOpenid = (to, next, id) => {
  /***
   *  @method setAddtoOpenid   链接追加参数
   *  @param {Object} to  路由to参数
   *  @param {string} id   用户id 
   *  @param {callfunction} next   路由next参数 
   *  @return {void}
   * 
   */
  //如果该页面需要登陆
  if (!store.state.user.token && !getCookie("user_info")) {
    //则获取token判断是否存在，不存在则唤起登陆页
    store.dispatch('doSetupLogin', true)

  } else {
    // token存在则追加inviter_id
    if (to.query?.inviter_id) {
      next();
    } else {
      next({
        path: to.path,
        query: {
          inviter_id: id,
          ...to.query
        }
      });
    }
  }
}

export const setBeforeEach = (router) => {
  router.beforeEach((to, from, next) => {
    store.dispatch("doSetupLoginPath", {
      name: to.name,
      query: to.query
    }); //记录跳转路径
    let id = store.state.user.info?.member_id
    if (to.meta.token) {
      setAddtoOpenid(to, next, id)
    } else {
      if (id) {
        //如果id存在
        if (to.query?.inviter_id) {
          //并且如果链接存在inviter_id 跳转next()
          next()

        } else {
          //否则追加inviter_id参数
          next({
            path: to.path,
            query: {
              inviter_id: id,
              ...to.query
            }
          })
        }
      } else {

        // 直接跳转
        next()
      }

    }



  })
}