import {
  get,

} from '../util/get/http'
import {
  dataAdd
} from '../util/set/util'
import {
  getDevice
} from '../util/get/util'


const dShareDisablePath = [
  '/new/pay/success',
  '/new/shop/paySuccess',
  '/active/prize_book',
  '/new/agent/goods',
  '/new/shop/enOrder',
  '/new/order/review'
]



const getShareDisablePath = (path) => {

  /***
   * @method getShareDisablePath  返回是否有匹配的禁止页面
   * @param {string} path 路径页面
   * @return {boolean} true | false
   * 
   */
  return dShareDisablePath.includes(path);
}
/* eslint-disable */

export const setAfterEach = async (router) => {

  router.afterEach(async (to, from, next) => {
    dataAdd(to);
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    let dShareState = !getShareDisablePath(to.path);
    if (getDevice().isWechat && dShareState) {

      // 是微信端 并且非禁止分享页面
      try {
        let dWechatShareInfo = {
          //微信分享信息
          title: to.meta.title,
          link: window.location.href,
          imgUrl: 'http://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021042919105559851.png',
          desc: '专注于为美业人提供最独具匠心的产品和服务'
        }
        let {
          signPackage: {
            appId,
            timestamp,
            nonceStr,
            signature,

          }
        } = await get('/index/getWechatShare', {
          params: {
            url: encodeURIComponent(window.location.href)
          }
        })
        wx.config({
          debug: false,
          appId,
          timestamp,
          nonceStr,
          signature,
          jsApiList: [
            // 所有要调用的 API 都要加到这个列表中
            "openAddress",
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'onMenuShareQZone',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'hideMenuItems',
            'hideOptionMenu',
            'hideAllNonBaseMenuItem',
            'showOptionMenu',
            'showAllNonBaseMenuItem',
            "wx-open-launch-weap",
            // "checkJsApi",
          ],
          openTagList: ["wx-open-launch-weapp"],
        })


        // 分享默认自定义配置  2020-7-20 大蕉    start

        wx.ready(function () {

          wx.updateAppMessageShareData(dWechatShareInfo)
          wx.onMenuShareTimeline(dWechatShareInfo);
          wx.onMenuShareAppMessage(dWechatShareInfo);
          wx.updateTimelineShareData(dWechatShareInfo)

          // 分享默认自定义配置  2020-7-20 大蕉    end
          if (getShareDisablePath(to.path)) {
            wx.hideOptionMenu();
            wx.hideAllNonBaseMenuItem();
            wx.hideMenuItems({
              menuList: [
                "menuItem:share:appMessage",
                "menuItem:share:timeline",
                "menuItem:share:qq",
                "menuItem:share:weiboApp",
                "menuItem:favorite",
                "menuItem:share:facebook",
                'menuItem:share:QZone',
                'menuItem:copyUrl',
                'menuItem:openWithQQBrowser',
                'menuItem:openWithSafari',
                'menuItem:share:email'
              ]
            });
            return false;
          }
          wx.showOptionMenu();
          wx.showAllNonBaseMenuItem();

        })


        wx.error(function (res) {
          console.log(res);
        })



      } catch (error) {
        console.error(error)
      }

    }


  })

}