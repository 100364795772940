let L = localStorage['19JIANG_SHOP'];
let U = L ? JSON.parse(L)?.user : null;

const state = {
  token: U?.token ? U.token : false, //用户token
  info: U?.info?.member_id ? U.info : {}, //用户信息
  xiaoeToken: U?.xiaoeToken ? U.xiaoeToken : false, //用户token
}

const getters = {
  // 获取token
  getToken() {
    return state.token
  },
  getInfo() {
    return state.info
  },


}

const mutations = {
  setToken(state, arg) {
    //修改token 记录
    state.token = arg;
  },
  setXiaoeToken(state, arg) {
    //修改小鹅通token 记录
    state.xiaoeToken = arg;
  },

  setUserInfo(state, arg) {
    //修改登录页唤起操作
    state.info = arg;
  }
}


const actions = {
  doToken(context, arg) {
    //执行token

    context.commit('setToken', arg)

  },
  doXiaoeTokenToken(context, arg) {
    //执行token
    context.commit('setXiaoeToken', arg)
  },

  doUserInfo(context, arg) {
    //执行登录页操作
    context.commit('setUserInfo', arg)
  },
  doUserClear(context, arg) {
    L = {};
    U = null;
    //执行登录页操作
    context.commit('setToken', false);
    context.commit('setUserInfo', {})
    context.commit('setXiaoeToken', false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}