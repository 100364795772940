import {
  getCartNum
} from "@view/cart/js/";
import store from '@/store';
const state = {
  cart_num: 0,
}

const mutations = {
  setCartNum(state, arg) {

    state.cart_num = arg;
  },


}


const actions = {
  async doSetCartNum(context, arg) {
    if (store.state.user.token) {
      let {
        cart_count
      } = await getCartNum();
      context.commit('setCartNum', cart_count)
    }
  },

}




export default {
  state,
  mutations,
  actions
}