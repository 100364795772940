const state = {
  setupLoginComponent: false, // 登录页是否唤起
  path: {} //登录前的路径，用于跳转
}

const getters = {
  getSetupLogin() {
    //获取登录页唤起状态
    return state.setupLoginComponent
  },
  getSetupLoginPath() {
    //获取登录页唤起状态
    return state.path
  }

}

const mutations = {
  setSetupLogin(state, arg) {
    //修改登录页唤起操作
    state.setupLoginComponent = arg;
  },

  setSetupLoginPath(state, arg) {
    //修改登录前的页面
    state.path = arg;
  },
}


const actions = {
  doSetupLogin(context, arg) {
    //执行唤起登录页操作
    context.commit('setSetupLogin', arg)
  },
  doSetupLoginPath(context, arg) {
    //执行登录页记录操作
    context.commit('setSetupLoginPath', arg)
  },
}




export default {
  state,
  getters,
  mutations,
  actions
}