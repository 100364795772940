const state = {
  history: [],

}

const mutations = {
  setSearchHistory(state, arg) {

    if (typeof arg === 'object') {
      state.history = [];
    } else {
      if (!state.history.find((item) => item == arg)) {
        if (state.history.length != 15) {
          state.history.unshift(arg);
        } else {
          state.history.splice(state.history.length - 1, 1);
          state.history.unshift(arg);
        }

      }
    }
  },

}

const actions = {
  async doSetSearchHistory(context, arg) {
    context.commit('setSearchHistory', arg)
  },


}




export default {
  state,
  mutations,
  actions
}