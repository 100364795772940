<template>
  <div id="">
    <div class="loginInput  flex-container">
      <img class="icon" src="@image/login/login_tel.png" />
      <input
        :value="value"
        @input="chageYZM($event.target.value)"
        class="flex1"
        maxlength="6"
        type="text"
        placeholder="请输入验证码"
      />
      <template>
        <div class="yzb_btn" @click="getyzm()" v-if="time == '60'">
          获取验证码
        </div>
        <div class="yzb_btn no_yzm_btn" v-else>{{ time }}s重新获取</div>
      </template>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { post } from "@get/http.js";

export default {
  props: {
    phone: {
      default: "",
      type: String, // 手机号
    },
    state: {
      default: "2",
      type: String,
      //短信类型 1为注册,2为登录,3为找回密码
    },
    value: {
      default: "",
      type: String,
    },
  },

  data: () => ({
    time: "60",
  }),
  watch: {},
  created() {
    this.types = this.type;
  },
  methods: {
    chageYZM(val) {
      this.$emit("update:value", val);
    },
    async getyzm() {
      if (this.phone.length != 11) {
        Toast("请输入正确的11位手机号码");
        return false;
      }
      Toast.loading({
        message: "发送中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      let { sms_time } = await post("/Connect/get_sms_captcha", {
        data: {
          type: this.state,
          phone: this.phone,
        },
      });
      Toast("十九匠商城通知:发送成功");
      this.time = sms_time;
      let timeer = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(timeer);
          this.time = "60";
        }
      }, 1000);
      this.$emit("getyzm");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.loginInput {
  padding: rem(60) 0 rem(28) 0;
  align-items: center;
  .icon,
  .show {
    width: rem(30);
    height: rem(30);
    display: block;
  }
  .phone_erroe {
    display: block;
    width: rem(36);
    height: rem(36);
  }
  .show {
    margin-left: rem(10);
  }
  .hide {
    width: rem(26);
    height: rem(21);
    margin-left: rem(10);
  }
  .solid {
    width: rem(2);
    height: rem(24);
    background: #ebebeb;
    margin: 0 rem(24);
  }
  input {
    font-size: rem(28);
    color: #808080;
  }

  span {
    font-size: rem(24);
    color: #808080;
  }
  input {
    border: none;
    width: rem(420);
    margin-left: rem(12);
  }
}

.yzb_btn {
  width: rem(180);
  height: rem(58);
  background: #ffebeb;
  border-radius: rem(29);
  text-align: center;
  line-height: rem(58);
  font-size: rem(24);
  color: #ff4747;
}
.no_yzm_btn {
  background: #f5f5f5;
  color: #b3b3b3;
}
</style>
