import {
  post
} from "@get/http";



export const getCartList = (store_id) => {
  /***
   * @method getCartList 购物车列表
   */
  return post("/lw.MemberCart/cart_list", {
    data: {
      store_id: store_id ? store_id : process.env.VUE_APP_STORE
    },
  });

}



export const getCartDel = (cart_id) => {
  /***
   * @method getCartDel 删除购物车
   */
  return post("/lw.MemberCart/cart_del", {
    data: {
      cart_id,
    },
  });

}




export const getCartAdd = (goods_id, quantity = 1, bl_id = "", is_point = 0) => {
  /***
   * @method getCartAdd 新增购物车
   */
  return post("/lw.MemberCart/cart_add", {
    data: {
      bl_id,
      id: goods_id,
      quantity,
      is_point
    },
  });

}


export const getCartQuantity = (cart_id, quantity) => {
  /***
   * @method getCartQuantity 购物车更新
   */
  return post("/lw.MemberCart/cart_edit_quantity", {
    data: {
      cart_id,
      quantity,
    },
  }, {
    result: true,
    toast: false,
    resultKey: 'code'
  });

}



export const getCartNum = (store_id) => {
  /***
   * @method getCartNum 检查购物车数量
   */
  //  lw.MemberCart.cart_count
  // /Pointcart/cart_coun
  return post("/lw.MemberCart/cart_count", {
    data: {
      store_id: store_id ? store_id : process.env.VUE_APP_STORE
    }
  }, {
    toast: false,
    setupLogin: false,
  });

}