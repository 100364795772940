import store from '@/store'
import {
  post
} from '@get/http'
export const getUserInfo = async () => {


  let {
    member_info
  } = await post("/v2.Member/index");
  store.dispatch("doUserInfo", member_info);
  await bindXiaoetUser(member_info)
  return member_info;
}




export const bindXiaoetUser = async (user) => {
  let data = null;
  let obj = {}
  if (user?.xiaoet_user_id?.length != 0) {
    data = await getXiaoetUserInfo('user_id', user?.xiaoet_user_id)
  } else {

    if (user.member_wxunionid) {
      data = await getXiaoetUserInfo('member_wxunionid', user.member_wxunionid);
    }


    if (data?.type == 'reg' && user.member_mobile || data == null && user.member_mobile) {

      data = await getXiaoetUserInfo('member_mobile', user.member_mobile);

    }


  }


  if (data?.type == 'user') {
    store.dispatch("doXiaoeTokenToken", data.data.data);
  }


  return false;
  if (data?.type == 'reg') {
    //用户不存在
    let {
      res
    } = await post(
      // 注册
      "/lw.Xiaoet/api", {
        data: {
          api_url: "https://api.xiaoe-tech.com/xe.user.register/1.0.0",
          method: "POST",
          data: {
            data: {
              ...obj,
              // // wx_union_id: user.member_wxunionid, //微信 union_id
              // phone: user.member_mobile, //联系方式
              avatar: user.member_avatar, //用户头像链接
              nickname: user.member_nickname, //微信 用户昵称
              // country: user.member_wxunionid, //国家
              // province: user.member_wxunionid, //省份
              // city: user.member_wxunionid, //城市
              // gender: user.member_wxunionid, //性别 0-无 1-男 2-女
              // wx_name: user.member_wxunionid, //真实姓名
              // wx_name: user.member_wxunionid, //真实姓名
              // wx_name: user.member_wxunionid, //真实姓名
            },
          },
        },
      }, {
        result: true,
        toast: false
      }
    );

    store.dispatch("doXiaoeTokenToken", res.data);
  } else if (data?.type == 'user') {
    store.dispatch("doXiaoeTokenToken", data.data.data);
  }


}


async function getXiaoetUserInfo(type, id) {
  let obj = {};
  let u = {}
  if (type == 'member_wxunionid') {
    obj.wx_union_id = id
  }
  if (type == 'member_mobile') {
    obj.phone = id
  }
  if (type == 'user_id') {
    u.user_id = id
  }

  let data = await post(
    "/lw.Xiaoet/api", {
      data: {
        api_url: "https://api.xiaoe-tech.com/xe.user.info.get/1.0.0",
        method: "POST",
        data: {
          ...u,
          data: {
            ...obj,
            field_list: ["nickname"],

          },
        },
      },
    }, {
      result: true,
      toast: false
    }
  );

  if (data.code == 2506) {
    //用户不存在
    return {
      type: 'reg',
      data
    }
  } else if (data.code == 0) {
    return {
      type: 'user',
      data
    }
  } else {
    return null;
  }
}