<template>
  <div id="login">
    <div class="login">
      <h1>欢迎来到十九匠商城</h1>
    </div>
    <div class="input_data">
      <o-login-input
        type="text"
        closeable
        placeholder="请输入你的手机号码"
        :value.sync="dUserPhoneNumber"
      >
      </o-login-input>
      <o-login-input
        v-if="dLoginModel == 'pw'"
        icon="sock"
        placeholder="请输入密码"
        :value.sync="dUserPassword"
        label="忘记密码"
      >
      </o-login-input>

      <oCaptcha
        :value.sync="dImageCaptcha"
        v-if="dLoginModel == 'pw'"
      ></oCaptcha>

      <oSms
        :phone="dUserPhoneNumber"
        :value.sync="dSmsCode"
        v-if="dLoginModel == 'sms'"
      ></oSms>
    </div>

    <!-- 登录按钮 -->
    <template>
      <div
        class="login-signBtn"
        v-if="
          dUserPhoneNumber.length < 6 || dLoginModel == 'sms'
            ? dSmsCode.length < 6
            : dUserPassword.length < 6 || dImageCaptcha.length < 4
        "
      >
        登&nbsp;&nbsp;&nbsp;录
      </div>
      <div class="login-signBtn login-signBtn_act" v-else @click="getSignin">
        登&nbsp;&nbsp;&nbsp;录
      </div>
    </template>

    <!-- 注册信息 -->
    <div class="login-reg flex-container">
      <b
        v-if="dLoginModel == 'pw'"
        @click="
          dLoginModel = 'sms';
          dImageCaptcha = '';
        "
        >验证码登录</b
      >
      <b
        v-if="dLoginModel == 'sms'"
        @click="
          dLoginModel = 'pw';
          dSmsCode = '';
        "
        >密码登录</b
      >
      <span class="flex1">没账号？ <i @click="regShow = true">注册</i></span>
    </div>
    <!-- 登录授权和协议 -->
    <!--  -->
    <div class="login-wx" v-if="getDevice.isWechat">
      <img @click="onWechat()" src="@image/login/wx_icon.png" />
      <!-- <p>登录即代表您已同意<span>用户协议</span>与<span>隐私政策</span></p> -->
      <p>登录即代表您已同意<span @click="docShow = true">用户协议</span></p>
    </div>
    <oReg :boo.sync="regShow"></oReg>
    <oDoc :boo.sync="docShow"></oDoc>
  </div>
</template>

<script>
import oLoginInput from "./components/loginInput/";
import { getDevice, delCookie, removeCookie } from "@get/util";
import { post } from "@get/http";
import { getUserInfo } from "@view/me/js/";
import oCaptcha from "./components/captcha/";
import oSms from "./components/sms/";
import oReg from "../reg/index.vue";
import { setBindingCustomerToSale } from "@set/util";
import oDoc from "./doc.vue";
import { getAutoWechatLogin } from "@get/wechatLogin";

export default {
  components: {
    oLoginInput,
    oCaptcha,
    oSms,
    oDoc,
    oReg,
  },

  data() {
    return {
      dUserPhoneNumber: "", //用户手机
      dUserPassword: "", //用户密码
      dImageCaptcha: "", //图片验证码
      dSmsCode: "", // 短信验证码
      dLoginModel: "sms", //登录模式  pw | sms
      getDevice: getDevice(),
      regShow: false, //唤起注册popup
      docShow: false,
    };
  },
  props: {
    boo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onWechat() {
      delCookie("user_info", ".19jiang.cn");
      delCookie("key", ".19jiang.cn");
      delCookie("new_cookie", ".19jiang.cn");
      delCookie("user_info", ".shop.19jiang.cn");
      delCookie("key", ".shop.19jiang.cn");
      delCookie("new_cookie", ".shop.19jiang.cn");

      removeCookie("key");
      removeCookie("new_cookie");
      await getAutoWechatLogin(this);
    },
    async getSignin() {
      let boo = this.dLoginModel == "pw";
      let args = boo
          ? {
              username: this.dUserPhoneNumber,
              password: this.dUserPassword,
              client_type: "wap",
              captcha: this.dImageCaptcha,
            }
          : {
              usermobile: this.dUserPhoneNumber,
              mobilecode: this.dSmsCode,
              client: "wap",
            },
        url = boo ? "/Login/index" : "/Connect/sms_login";

      let { info, token } = await post(url, {
        data: args,
      });

      this.$store.dispatch("doToken", token);
      await getUserInfo();
      setBindingCustomerToSale();
      this.$store.dispatch("doSetupLogin", false);

      if (this.$route.name != this.$store.getters?.getSetupLoginPath?.name) {
        this.$router.push(this.$store.getters.getSetupLoginPath);
      }
      // window.location.reload();
      //  else {
      //   this.$emit("update:boo", false);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#login {
  //登录头部信息
  height: 100%;
  padding: 0 rem(48);
  background: url("~@image/login/login_bg.png") no-repeat #fff;
  background-size: 100% rem(300);
}
.login {
  h1 {
    padding: rem(110) 0 rem(60);
    font-size: rem(48);
    // font-weight: bold;
    color: $color_333;
  }
}

.login-signBtn {
  // 登录按钮
  font-size: rem($f_32);
  font-family: "pfb";
  color: $color_fff;
  width: rem(640);
  height: rem(86);
  background: $color_e6e6e6;
  border-radius: rem(43);
  text-align: center;
  line-height: rem(86);
  margin: rem(86) auto 0;
}
.login-signBtn_act {
  background: $color_ff4747;
}

.login-reg {
  font-size: rem(24);
  font-family: "pf";
  margin-top: rem(32);
  b {
    color: #333333;
  }
  span {
    color: #b3b3b3;
    text-align: right;
  }
  i {
    color: #4c6aff;
  }
}

.login-wx {
  //微信登录和授权
  padding: rem(100) 0 rem(200);
  img {
    margin: 0 auto;
    width: rem(120);
    height: rem(120);
    display: block;
    cursor: pointer;
  }
  p {
    text-align: center;
    font-size: rem($f_20);
    color: $color_b3b3b3;
    margin-top: rem($side_24);
    span {
      text-decoration: underline;
    }
  }
}
</style>
