<template>
  <van-popup
    v-model="regShow"
    closeable
    position="bottom"
    round
    @close="closePopup()"
    :style="{ height: '95%' }"
  >
    <div id="login">
      <div class="login">
        <h1>欢迎来到十九匠商城</h1>
      </div>
      <div class="input_data">
        <o-login-input
          type="text"
          closeable
          placeholder="请输入你的手机号码"
          :value.sync="dUserPhoneNumber"
        >
        </o-login-input>
        <o-login-input
          icon="sock"
          placeholder="请输入密码"
          :value.sync="dUserPassword"
        >
        </o-login-input>
        <o-login-input
          icon="sock"
          placeholder="请载入输入密码"
          :value.sync="dUserPassword2"
        >
        </o-login-input>

        <oSms state="1" :phone="dUserPhoneNumber" :value.sync="dSmsCode"></oSms>
      </div>
      <div
        class="login_btn"
        v-if="
          dUserPhoneNumber.length < 11 ||
            dUserPassword.length < 6 ||
            dUserPassword2.length < 6 ||
            dSmsCode.length < 4
        "
      >
        注册
      </div>
      <div class="login_btn yes_login" v-else @click="onSubmit">注册</div>
      <div class="reg flex-container">
        <!-- <b>验证码登录</b> -->
        <span class="flex1">已有账号 <i @click="closePopup()">去登陆</i></span>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Popup, Toast } from "vant";
import oLoginInput from "../login/components/loginInput/";
import oSms from "../login/components/sms/";
import { post } from "@get/http";
import { getQueryVariable } from "@get/util";
import { getUserInfo } from "@view/me/js/";
export default {
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    vanPopup: Popup,
    oLoginInput,
    oSms,
  },
  data() {
    return {
      regShow: false,
      dUserPhoneNumber: "", //用户手机
      dUserPassword: "", //用户密码
      dUserPassword2: "", //用户密码
      dSmsCode: "", // 短信验证码
    };
  },
  watch: {
    boo() {
      this.regShow = this.boo;
    },
  },
  created() {
    this.regShow = this.boo;
  },
  methods: {
    closePopup() {
      this.$emit("update:boo", false);
    },
    async onSubmit() {
      let inviter_id =
        sessionStorage["RENINE_SHOP_I_ID"] || getQueryVariable("inviter_id"); //邀请人ID

      // let inviterId = this.$store.state.common.inviter_id; // 获取邀请人id
      let { token, info } = await post("/Connect/sms_register", {
        data: {
          phone: this.dUserPhoneNumber,
          password: this.dUserPassword,
          password_confirm: this.dUserPassword2,
          captcha: this.dSmsCode,
          inviter_id: inviter_id,
          client: "wap",
        },
      });
      this.$store.dispatch("doUserInfo", info);
      this.$store.dispatch("doToken", token);
      await getUserInfo();
      this.$router.push({ name: "HomeIndex" });
      sessionStorage.removeItem("RENINE_SHOP_I_ID");

      window.location.reload();

      Toast("十九匠商城通知:注册成功");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#login {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  min-height: 100%;
  background: #fff;
  padding: 0 rem(48);
  background: url("~@image/me/forget_bg.png") no-repeat #fff;
  background-size: 100% rem(300);
}
.login {
  h1 {
    padding: rem(110) 0 rem(60);
    font-weight: bold;
    font-size: rem(48);
    color: #333333;
  }
}

.login_btn {
  font-size: rem(32);
  font-weight: bold;
  color: #ffffff;
  width: rem(640);
  height: rem(86);
  background: #e6e6e6;
  border-radius: rem(86/2);
  text-align: center;
  line-height: rem(86);
  margin: rem(86) auto 0;
}
.yes_login {
  background: #ff4747;
}

.reg {
  font-size: rem(24);
  margin-top: rem(32);
  b {
    color: #333333;
  }
  span {
    color: #b3b3b3;
    text-align: right;
  }
  i {
    color: #4c6aff;
  }
}
</style>
