<template>
  <div id="">
    <form>
      <div class="loginInput flex-container">
        <img class="icon" :src="getTypeIcon(icon)" />

        <input
          :value="value"
          @input="chageYZM($event.target.value)"
          class="flex1"
          :maxlength="len"
          :type="types"
          autocomplete="off"
          :placeholder="placeholder"
        />

        <template v-if="type == 'password'">
          <img
            class="hide"
            src="@image/login/login_hide.png"
            @click="types = 'password'"
            v-if="types == 'text'"
          />
          <img
            class="show"
            src="@image/login/login_show.png"
            @click="types = 'text'"
            v-else
          />
        </template>
        <img
          src="@image/public/error_1.png"
          class="phone_erroe"
          @click="chageYZM('')"
          v-if="closeable"
        />

        <div v-if="label" class="solid"></div>
        <span v-if="label" @click="$router.push({ name: 'New_Forget' })">{{
          label
        }}</span>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    icon: {
      //左侧图表
      default: "tel",
      type: String,
    },
    type: {
      // 输入框模式
      default: "password", //password || text
    },
    placeholder: {
      default: "",
    },
    closeable: {
      //是否需求清空输入框
      default: false,
      type: Boolean,
    },
    len: {
      //最大输入数
      default: "20",
      type: String,
    },
    value: {
      default: "",
    },
  },

  data: () => ({
    types: "password",
  }),
  created() {
    //do something after creating vue instance
    this.types = this.type;
  },
  methods: {
    getTypeIcon(icon) {
      if (icon == "sock") {
        return require("@image/login/login_sock.png");
      } else {
        return require("@image/login/login_tel.png");
      }
    },
    chageYZM(val) {
      this.$emit("update:value", val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.loginInput {
  // padding: rem(60) 0;
  padding: rem(60) 0 rem(28) 0;
  align-items: center;
  .icon,
  .show {
    width: rem(30);
    height: rem(30);
    display: block;
  }
  .phone_erroe {
    display: block;
    width: rem(36);
    height: rem(36);
  }
  .show {
    margin-left: rem(10);
  }
  .hide {
    width: rem(26);
    height: rem(21);
    margin-left: rem(10);
  }
  .solid {
    width: rem(2);
    height: rem(24);
    background: #ebebeb;
    margin: 0 rem(24);
  }
  input {
    font-size: rem(28);
    color: #808080;
  }

  span {
    font-size: rem(24);
    color: #808080;
  }
  input {
    border: none;
    width: rem(420);
    margin-left: rem(12);
  }
}

.yzb_btn {
  width: rem(180);
  height: rem(58);
  background: #ffebeb;
  border-radius: rem(29);
  text-align: center;
  line-height: rem(58);
  font-size: rem($f_24);
  color: $color_ff4747;
}
.no_yzm_btn {
  background: $color_f5f5f5;
  color: $color_b3b3b3;
}
</style>
