const getDevice = () => {
  /**
   * @method getDevice 获取当前手机设备和是否是微信端
   * @return {object<boolean>}  
   * @date 2021-06-13 
   */
  let device = navigator.userAgent,
    ua = window.navigator.userAgent.toLowerCase();
  return {
    isAndroid: device.indexOf("Android") > -1 || device.indexOf("Adr") > -1, //android终端
    isIOS: !!device.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    isWechat: ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false
  }
}


const getQueryVariable = (variable) => {

  /**
   * @method getQueryVariable  history值的获取链接参数
   * @param {string} variable  需要查找的链接参数
   * @return {string} 如果值存在则返回该值 || false
   */

  let query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      // sessionStorage[variable] = pair[1]
      return pair[1];
    }
  }
  return false;
}



// 获取cookie
const getCookie = (cname) => {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1)
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length)
  }
  return ''
}

// 清除cookie
const clearCookie = (cname) => {
  setCookie(cname, '', -1)
}

//删除指定cookie的值
function removeCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  var cookieStr = "";
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      document.cookie = c + ';expires=' + new Date(0).toUTCString()
    } else {
      cookieStr += c;
      cookieStr += ";";
    }
    document.cookie = cookieStr;

  }

}

/**
28      * 删除cookie
29      * name: 删除cookie名
30      * domain: 所在的域
31      */
function delCookie(name, domain) {
  document.cookie = name + '=' + getCookie(name) + ';expires=' + (new Date(1)) + ';domain=' + domain + ';path=/';
}



// 设置cookie
const setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + '; ' + expires + "; path=/;domain=" + getCookieDomain() + ';'
}



const getCookieDomain = () => {
  var host = env.H5_HOST;
  var ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  if (ip.test(host) === true || host === 'localhost') return host;
  var regex = /([^]*).*/;
  var match = host.match(regex);
  if (typeof match !== "undefined" && null !== match) host = match[1];
  if (typeof host !== "undefined" && null !== host) {
    var strAry = host.split(".");
    if (strAry.length > 1) {
      //判断是否是双后缀
      regex = /[\w].+\.(com|net|org|gov|edu)\.cn$/;
      match = host.match(regex);
      if (strAry.length > 2 && typeof match !== "undefined" && null !== match) {
        host = strAry[strAry.length - 3] + "." + strAry[strAry.length - 2] + "." + strAry[strAry.length - 1];
      } else {
        host = strAry[strAry.length - 2] + "." + strAry[strAry.length - 1];
      }

    }
  }
  return '.' + host;
}



export {
  getDevice,
  getQueryVariable,
  getCookie,
  removeCookie,
  clearCookie,
  setCookie,
  getCookieDomain,
  delCookie
}