<template>
  <van-popup
    v-model="docShow"
    closeable
    position="bottom"
    round
    @close="closePopup()"
    :style="{ height: '95%' }"
  >
    <h1>{{ title }}</h1>
    <div v-html="content"></div>
  </van-popup>
</template>

<script>
import { get } from "@get/http";
import { Popup } from "vant";
export default {
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    vanPopup: Popup,
  },

  data() {
    return {
      docShow: false,
      content: "",
      title: "",
    };
  },
  watch: {
    boo() {
      this.docShow = this.boo;
    },
  },
  methods: {
    closePopup() {
      this.$emit("update:boo", false);
    },
  },
  async created() {
    this.docShow = this.boo;
    let { document_content, document_title } = await get("/Document/agreement");
    this.content = document_content;
    this.title = document_title;
  },
};
</script>

<style lang="sass" scoped></style>
