import Vue from 'vue'
import Router from 'vue-router'
import {
  setBeforeEach
} from './beforeEach.js'
import {
  setAfterEach
} from './afterEach'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}


const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}



/**
 *   routes meta参数
 *   token : boolean  是否需要登陆后才能进入 arg : false | true  , required
 *   keepAlive : boolean  是否需要缓存页面 arg : false | true , required
 *   footer : boolean  是否需要底部bar  arg : false | true , required
 *   title : string    标题
 */


const HomeBase = () => import('@c/home/HomeBase')
// const tabBar = () => import('@c/tabbar1')
const tabBar = () => import('@c/tabbar2')
const tabBar1 = () => import('@c/tabbar')

const router = new Router({
  mode: 'history', //历史模式
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.saveSrollTop) {
        from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      };
    }
  },
  routes: [{
      path: '',
      redirect: '/home/index',
      name: 'defaultIndex',
      meta: {
        token: false,
        keepAlive: true,
        footer: true,
        title: '十九匠商城',
        returnPage: true,

      }
    },
    {
      path: '/study/',
      component: tabBar,
      children: [{
        path: 'class',
        name: 'StudyClass',
        component: () => import('@view/study/class.vue'),
        meta: {
          token: false,
          keepAlive: false,
          footer: true,
          title: '课程',
          returnPage: true,
        }
      }],
    },

    {
      path: '/study/',
      component: tabBar,
      children: [{
        path: 'index',
        name: 'Study',
        component: () => import('@view/study/'),
        meta: {
          token: true,
          keepAlive: false,
          footer: true,
          title: '学习中心',
          returnPage: true,
        }
      }],
    },
    {
      path: '/home/',
      component: tabBar,
      children: [{
          path: 'index',
          name: 'HomeIndex',
          component: () => import('@view/home/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '十九匠商城',
            returnPage: true,
          }
        },
        {
          path: 'index2',
          name: 'HomeIndex2',
          component: () => import('@view/home/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '十九匠商城',
            returnPage: true,
          }
        },
        {
          path: 'index3',
          name: 'HomeIndex3',
          component: () => import('@view/home/view/index/index.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '十九匠商城',
            returnPage: true,
          }
        },
        {
          path: 'live/list',
          name: 'Home_LiveList',
          component: () => import('@view/home/view/index/components/study/live/list.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '直播',
            returnPage: true,
          }
        },
        {
          path: 'free/list',
          name: 'Home_FreeList',
          component: () => import('@view/home/view/index/components/study/free/list.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '限时免费',
            returnPage: true,
          }
        },
        {
          path: 'offline/list',
          name: 'Home_offloneList',
          component: () => import('@view/home/view/index/components/study/offline/list.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '线下课',
            returnPage: true,
          }
        },
        {
          path: 'class/list',
          name: 'Home_classList',
          component: () => import('@view/home/view/index/components/study/class/class.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '训练营',
            returnPage: true,
          }
        },
        {
          path: 'onlyProduct',
          name: 'HomeOnlyProduct',
          component: () => import('@view/home/view/onlyProduct/'),
          meta: {
            token: true,
            keepAlive: true,
            footer: false,
            title: '私人尊享定制套',
            returnPage: true,
          }
        },

        {
          path: 'goodsSeckill',
          name: 'goodsSeckill',
          component: () => import('@view/home/view/goodsSeckill/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '秒杀专区',
            returnPage: true,
          }
        },

        {
          path: 'goodsreview',
          name: 'HomeGoodsReview',
          component: () => import('@view/home/view/goodsReview/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            returnPage: true,
            title: '商品评价'
          }
        },
        {
          path: 'goodsbuyershow',
          name: 'HomeGoodsBuyershow',

          component: () => import('@view/home/view/buyershow/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            returnPage: true,
            title: '买家秀'
          }
        },


        {
          path: 'goodsdetail2',
          name: 'HomeGoodsdetail',
          component: () => import('@view/home/view/goods/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '商品详情',
            returnPage: true,
          }
        },
        {
          path: 'goodsdetail',
          name: 'HomeGoodsdetail2',
          component: () => import('@view/home/view/goods/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '商品详情',
            returnPage: true,
          }
        },
        {
          path: 'goodsmultiple',
          name: 'goodsMultiple',
          component: () => import('@view/home/view/goodsMultiple/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '商品详情',
            returnPage: true,
          }
        },
        {
          path: 'goodstailor',
          name: 'goodsTailor',
          component: () => import('@view/home/view/goodsTailor/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '尊享',
            returnPage: true,
          }
        },
        {
          path: 'goodsSearch',
          name: 'HomeGoodsSearch',
          component: () => import('@view/home/view/goodsSearch/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '商品搜索',
            returnPage: true,
          }
        },
        {
          path: 'luck/draw',
          name: 'LuckDraw',
          component: () => import('@view/me/view/luck/draw.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '抽奖',
            returnPage: true,
          }
        },

      ]
    },
    {
      path: '/list/',
      component: tabBar,
      children: [{
          path: 'index',
          name: 'List',
          component: () => import('@view/list/'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '产品分类',
            returnPage: true,
          }
        }, {
          path: 'product',
          name: 'ListProduct',
          component: () => import('@view/list/product.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: false,
            title: '全部产品',
            returnPage: true,
          }
        }, {
          path: 'studyproduct',
          name: 'studyProduct',
          component: () => import('@view/list/studyProduct.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '全部产品',
            returnPage: true,
          }
        },
        {
          path: 'allproduct',
          name: 'allProduct',
          component: () => import('@view/list/allproduct.vue'),
          meta: {
            token: false,
            keepAlive: true,
            footer: true,
            title: '分类',
            returnPage: true,
          }
        },
      ]
    },
    {
      path: '/me/',
      component: tabBar,
      children: [{
          path: 'index2',
          name: 'Me2',
          component: () => import('@view/me/'),
          meta: {
            token: true,
            keepAlive: false,
            footer: true,
            title: '我的',
            returnPage: true,
          }
        },
        {
          path: 'index',
          name: 'Me',
          component: () => import('@view/me/c_index.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: true,
            title: '我的',
            returnPage: true,
          }
        },
        {
          path: 'info',
          name: 'MeInfo',
          component: () => import('@view/me/view/info/index.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '我的信息',
            returnPage: true,
          }
        },
        {
          path: 'address',
          name: 'Address',
          component: () => import('@view/me/view/address/index.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '我的地址',
            returnPage: true,
          }
        },

        {
          path: 'luck',
          name: 'luckRecord',
          component: () => import('@view/me/view/luck/record'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '中奖记录',
            returnPage: true,
          }
        },
        {
          path: 'points',
          name: 'points',
          component: () => import('@view/me/view/points/'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '签到',
            returnPage: true,
          }
        },
        {
          path: 'signin',
          name: 'signin',
          component: () => import('@view/me/view/signin/'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '签到',
            returnPage: true,
          }
        },
        {
          path: 'signin/rule',
          name: 'signinRule',
          component: () => import('@view/me/view/signin/rule'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '签到规则',
            returnPage: true,
          }
        },
        {
          path: 'signin/log',
          name: 'signinLog',
          component: () => import('@view/me/view/signin/log'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '记录',
            returnPage: true,
          }
        },
        {
          path: 'points/log',
          name: 'pointsLLog',
          component: () => import('@view/me/view/points/log.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '积分动态',
            returnPage: true,
          }
        },

        {
          path: 'points/details',
          name: 'pointsDetaiils',
          component: () => import('@view/me/view/points/details.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '商品详情',
            returnPage: true,
          }
        },
        {
          path: 'team',
          name: 'Team',
          component: () => import('@view/me/view/team'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '我的团队',
            returnPage: true,
          }
        },
        {
          path: 'team2',
          name: 'Team2',
          component: () => import('@view/me/view/team/2/'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '我的团队',
            returnPage: false,
          }
        },
        {
          path: 'income',
          name: 'Income',
          component: () => import('@view/me/view/income'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '收益明细',
            returnPage: false,
          }
        },
        {
          path: 'suggest',
          name: 'Suggest',
          component: () => import('@view/me/view/suggest'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '投诉建议反馈',
            returnPage: true,
          }
        },
        {
          path: 'safe',
          name: 'Safe',
          component: () => import('@view/me/view/safe'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '安全中心',
            returnPage: true,
          }
        },
        // {
        //   path: 'chat',
        //   name: 'Chat',
        //   component: () => import('@view/me/view/chat'),
        //   meta: {
        //     token: true,
        //     keepAlive: false,
        //     footer: false,
        //     title: '客服',
        //     returnPage: true,
        //   }
        // },


        {
          path: 'real',
          name: 'Real',
          component: () => import('@view/me/view/real'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '认证中心',
            returnPage: true,
          }
        },
        {
          path: 'vip',
          name: 'Vip',
          component: () => import('@view/me/view/vip'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '会员中心',
            returnPage: true,
          }
        },
        {
          path: 'vip/details',
          name: 'VipDetails',
          component: () => import('@view/me/view/vip/details.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '会员成长',
            returnPage: true,
          }
        },
        {
          path: 'vip/me',
          name: 'VipMe',
          component: () => import('@view/me/view/vip/me.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '我的会员',
            returnPage: true,
          }
        },
        {
          path: 'preSale/step1',
          name: 'preSaleStep1',
          component: () => import('@view/me/view/preSale/step1'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '提交订单',
            returnPage: true,
          }
        },
        {
          path: 'preSale/step2',
          name: 'preSaleStep2',
          component: () => import('@view/me/view/preSale/step2'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '付尾款',
            returnPage: true,
          }
        },


        {
          path: 'preSale/success',
          name: 'preSaleSuccess',
          component: () => import('@view/me/view/preSale/success'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '支付成功',
            returnPage: false,
          }
        },

        {
          path: 'preSale/buy',
          name: 'preSaleBuy',
          component: () => import('@view/me/view/preSale/buy'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '支付',
            returnPage: true,
          }
        },

        {
          path: 'buy',
          name: 'Buy',
          component: () => import('@view/me/view/buy'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单明细',
            returnPage: true,
          }
        },
        {
          path: 'buy_only',
          name: 'Buy_Only',
          component: () => import('@view/me/view/buy_only'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单明细',
            returnPage: true,
          }
        },

        {
          path: 'buy_multiple',
          name: 'Buy_Multiple',
          component: () => import('@view/me/view/buy_multiple'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单明细',
            returnPage: true,
          }
        },

        {
          path: 'buy/success',
          name: 'BuySuccess',
          component: () => import('@view/me/view/buy/success.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '支付成功',
            returnPage: true,
          }
        },
        {
          path: 'order',
          name: 'Order',
          component: () => import('@view/me/view/order'),
          meta: {
            token: true,
            keepAlive: true,
            footer: false,
            title: '我的订单',
            returnPage: true,
          }
        },
        {
          path: 'order/xiaoet',
          name: 'Xiaoet_Order',
          component: () => import('@view/me/view/order/xiaoet/index.vue'),
          meta: {
            token: true,
            keepAlive: true,
            footer: false,

            title: '我的订单',
            returnPage: false,
          }
        },
        {
          path: 'order/xiaoet/details',
          name: 'Xiaoet_Order_details',
          component: () => import('@view/me/view/order/xiaoet/details.vue'),
          meta: {
            token: true,
            keepAlive: true,
            footer: false,
            title: '我的订单',
            returnPage: false,
          }
        },

        {
          path: 'order/service',
          name: 'OrderService',
          component: () => import('@view/me/view/order/service'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '售后订单',
            returnPage: false,
          }
        },
        {
          path: 'order/service/reason',
          name: 'OrderServiceReason',
          component: () => import('@view/me/view/order/service/reason'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '售后订单详情',
            returnPage: true,
          }
        },
        {
          path: 'order/service/chooese',
          name: 'OrderServiceChooese',
          component: () => import('@view/me/view/order/service/chooese'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '售后选择',
            returnPage: true,
          }
        },
        {
          path: 'order/service/detail',
          name: 'OrderServiceDetail',
          component: () => import('@view/me/view/order/service/detail'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '售后订单详情',
            returnPage: true,
          }
        },
        {
          path: 'order/review',
          name: 'OrderReview',
          component: () => import('@view/me/view/order/review'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单评价',
            returnPage: true,
          }
        },
        {
          path: 'order/search',
          name: 'OrderSearch',
          component: () => import('@view/me/view/order/search'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '搜索订单',
            returnPage: true,
          }
        },
        {
          path: 'order/detail',
          name: 'OrderDetail',
          component: () => import('@view/me/view/order/detail'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单详情',
            returnPage: true,
          }
        },
        {
          path: 'order/mail',
          name: 'OrderMail',
          component: () => import('@view/me/view/order/mail'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '订单物流',
            returnPage: true,
          }
        },
        {
          path: 'promotionCenter',
          name: 'PromotionCenter',
          component: () => import('@view/me/view/promotionCenter'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '推广中心',
            returnPage: false,
          }
        },
        {
          path: 'money/reload',
          name: 'MoneyReload',
          component: () => import('@view/me/view/money/2/reload.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '提现记录',
            returnPage: false,
          }
        },
        {
          path: 'money',
          name: 'Money',
          component: () => import('@view/me/view/money/2/'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '零钱',
            returnPage: false,
          }
        },
        {
          path: 'money_old',
          name: 'Money_old',
          component: () => import('@view/me/view/money'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '零钱',
            returnPage: true,
          }
        },
        {
          path: 'money/apply',
          name: 'Apply',
          component: () => import('@view/me/view/money/apply'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '申请提现',
            returnPage: true,
          }
        },
        {
          path: 'money/bill',
          name: 'MoneyBill',
          component: () => import('@view/me/view/money/moneyBill'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '零钱账单',
            returnPage: true,
          }
        },
        {
          path: 'money/bill/detail',
          name: 'MoneyBillDetail',
          component: () => import('@view/me/view/money/static'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '账单详情',
            returnPage: true,
          }
        },
        {
          path: 'money/toBill',
          name: 'toMoneyBill',
          component: () => import('@view/me/view/money/toMoneyBill'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '提现记录账单',
            returnPage: true,
          }
        },
        {
          path: 'money/toBill/detail',
          name: 'toMoneyBillDetail',
          component: () => import('@view/me/view/money/moneyStatic'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '提现账单详情',
            returnPage: true,
          }
        },
        {
          path: 'coupon',
          name: 'Coupon',
          component: () => import('@view/me/view/coupon'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '红包卡券',
            returnPage: true,
          }
        },
        {
          path: 'coupon2',
          name: 'Coupon2',
          component: () => import('@view/me/view/coupon/2'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '红包卡券',
            returnPage: true,
          }
        },
        {
          path: 'coupon/reload',
          name: 'CouponReload',
          component: () => import('@view/me/view/coupon/2/indexed'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '使用记录',
            returnPage: true,
          }
        },
        {
          path: 'coupon/history',
          name: 'couponHistory',
          component: () => import('@view/me/view/coupon/history.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '历史红包卡券',
            returnPage: true,
          }
        },
        {
          path: 'coupon/centre',
          name: 'couponCentre',
          component: () => import('@view/me/view/coupon/centre2.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '领券中心',
            returnPage: true,
          }
        },
        {
          path: 'coupon/centre2',
          name: 'couponCentre2',
          component: () => import('@view/me/view/coupon/centre.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '领券中心',
            returnPage: true,
          }
        },

        {
          path: 'coupon/in',
          name: 'couponIn',
          component: () => import('@view/me/view/coupon/in.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '专属优惠券领取',
            returnPage: true,
          }
        },


        {
          path: 'coupon/hide',
          name: 'couponHide',
          component: () => import('@view/me/view/coupon/hide.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '专属优惠券领取',
            returnPage: true,
          }
        },
        {
          path: 'coupon/only',
          name: 'couponOnly',
          component: () => import('@view/me/view/coupon/onlyUse.vue'),
          meta: {
            token: true,
            keepAlive: false,
            footer: false,
            title: '专属优惠券领取',
            returnPage: true,
          }
        },
      ]
    },
    {
      path: '/baby/',
      component: tabBar,
      children: [{
        path: 'index',
        name: 'Baby',
        component: () => import('@view/baby/'),
        meta: {
          token: false,
          keepAlive: true,
          footer: true,
          title: '全部宝贝',
          returnPage: true,
        }
      }, ]
    },
    {
      path: '/cart/',
      component: tabBar,
      children: [{
        path: 'index',
        name: 'Cart',
        component: () => import('@view/cart/'),
        meta: {
          token: true,
          keepAlive: false,
          footer: true,
          title: '购物车',
          returnPage: true,
        }
      }, ]
    },
    {
      path: '/h5/chat/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
          path: 'review',
          name: 'chatReviewIndex',
          component: () => import('@view/h5/chat/review/index.vue'),
          meta: {
            footer: {
              show: false
            },
            title: '评价',
            returnPage: true,
            keepAlive: true
          }
        },
        {
          path: 'index',
          name: 'chat_qx',
          component: () => import('@view/h5/chat/index/index.vue'),
          meta: {
            footer: {
              show: false
            },
            title: '客服',
            returnPage: true,
            keepAlive: true
          }
        },
      ]

    },

    {
      path: '/h5/shopdoc/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
        path: 'index',
        name: 'shopDoc_Index',
        component: () => import('@view/h5/shopDoc/index.vue'),
        meta: {
          footer: {
            show: false
          },
          title: '首页',
          returnPage: true,
          keepAlive: true
        }
      }, ]
    },
    {
      path: '/tool/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
        path: 'report/list',
        name: 'Report_List',
        component: () => import('@view/h5/report/list'),
        meta: {
          footer: {
            show: false
          },
          title: '列表',
          returnPage: true,
          keepAlive: false
        }
      }, {
        path: 'report/index',
        name: 'Report_Index',
        component: () => import('@view/h5/report/index'),
        meta: {
          footer: {
            show: false
          },
          title: '列表',
          returnPage: true,
          keepAlive: false
        }
      }, ]
    },
    {
      path: '/h5/offlineMall/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
        path: 'index',
        name: 'offlineMall_Index',
        component: () => import('@view/h5/offlineMall/'),
        meta: {
          footer: {
            show: false
          },
          title: '首页',
          returnPage: true,
          keepAlive: false
        }
      }, ]
    },
    {
      path: '/h5/wenandoc/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
        path: 'index',
        name: 'wenanDoc_Index',
        component: () => import('@view/h5/wenanDoc/index.vue'),
        meta: {
          footer: {
            show: false
          },
          title: '首页',
          returnPage: true,
          keepAlive: true
        }
      }, ]
    },

    {
      path: '/h5/createOnlyProduct',
      name: 'createOnlyProduct',
      component: () => import('@view/h5/createHB/index.vue'),
      meta: {
        token: true,
        keepAlive: false,
        footer: false,
        returnPage: true,
        title: '生成尊享专属案例图'
      }
    },

    {
      path: '/h5/safecode',
      name: 'safeCode',
      component: () => import('@view/h5/safeCode/index.vue'),
      meta: {
        token: false,
        keepAlive: false,
        footer: false,
        returnPage: true,
        title: '防伪码查询'
      }
    },

    {
      path: '/h5/generate/list',
      name: 'generateList',
      component: () => import('@view/h5/generate/list.vue'),
      meta: {
        token: false,
        keepAlive: true,
        footer: false,
        returnPage: true,
        title: '尊享海报选择列表'
      }
    },

    {
      path: '/h5/generate/index',
      name: 'generate',
      component: () => import('@view/h5/generate/index.vue'),
      meta: {
        token: false,
        keepAlive: true,
        footer: false,
        returnPage: true,
        title: '长按保存海报'
      }
    },

    {
      path: '/h5/generate/doc',
      name: 'generateDoc',
      component: () => import('@view/h5/generate/doc.vue'),
      meta: {
        token: false,
        keepAlive: true,
        footer: false,
        returnPage: true,
        title: '尊享海报文案选择列表'
      }
    },



    {
      path: '/h5/course/',
      name: 'Course_Index',
      component: () => import('@view/course/index.vue'),
      meta: {
        token: false,

        keepAlive: false,
        footer: false,
        returnPage: true,
        title: '首页'
      }
    },
    {
      path: '/h5/xcvdghrtyrasdwdhe2021',
      name: 'fhgkjtyshfdjnfsgh2021',
      component: () => import('@c/login/fhgkjtyshfdjnfsgh2021/index.vue'),
      meta: {
        token: false,
        keepAlive: false,
        footer: false,
        returnPage: true,
        title: '列表'
      }
    },
    {
      path: '/h5/bbsMall/',
      component: () => import('@view/h5/bbsMall/components/bottom.vue'),
      children: [{
          path: 'index',
          name: 'bbsMall_Index',
          component: () => import('@view/h5/bbsMall/Index/index.vue'),
          meta: {
            footer: {
              show: false
            },
            title: '首页',
            returnPage: true,
            keepAlive: true
          }
        },
        {
          path: 'detail',
          name: 'bbsMall_Detail',
          component: () => import('@view/h5/bbsMall/Detail/index.vue'),
          meta: {
            footer: {
              show: false
            },
            title: '详情',
            returnPage: true,
            keepAlive: true
          }
        },
        {
          path: 'me',
          name: 'bbsMall_Me',
          component: () => import('@view/h5/bbsMall/Me/index.vue'),
          meta: {
            footer: {
              show: false
            },
            token: true,
            returnPage: true,
            title: '个人资料',
            keepAlive: false
          }
        },
      ]
    },
  ]

})



setBeforeEach(router)
setAfterEach(router)



export default router;