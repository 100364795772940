<template>
  <div id="app">
    <!-- shell -->
    <oLoginSetup></oLoginSetup>
    <router-view></router-view>
  </div>
</template>

<script>
import oLoginSetup from "@c/login/setup";

import { getAutoWechatLogin } from "@get/wechatLogin";
import { getQueryVariable } from "@get/util";
export default {
  components: {
    oLoginSetup,
  },
  data() {
    return {};
  },

  created() {},

  async mounted() {
    sessionStorage["RENINE_SHOP_I_ID"] = getQueryVariable("inviter_id"); //记录邀请者ID
    await this.getAutoWechatLogin(this);
    // this.$store.dispatch("doSetChatStateKefu", "");
  },
  methods: {
    getAutoWechatLogin,
  },
};
</script>
